

var show = 7; //最初に表示する件数
var show5 = 6; //最初に表示する件数
var num = 7;  //clickごとに表示したい件数
var num5 = 5;  //clickごとに表示したい件数
var contents = '.js_more'; // 対象のlist
var contents_hikido = '.js_more_hikido'; // 対象のlist
var contents_orido = '.js_more_orido'; // 対象のlist
var contents_wide = '.js_more_wide'; // 対象のlist
var contents_bouka = '.js_more_bouka'; // 対象のlist
var contents_sencer = '.js_more_sencer'; // 対象のlist
var contents_nstoplight = '.js_more_nstoplight'; // 対象のlist


$(contents_hikido + ':nth-child(n + ' + (show + 1) + ')').addClass('is-hidden');
$(contents_orido + ':nth-child(n + ' + (show5 + 1) + ')').addClass('is-hidden');
$(contents_wide + ':nth-child(n + ' + (show5 + 1) + ')').addClass('is-hidden');
$(contents_bouka + ':nth-child(n + ' + (show5 + 1) + ')').addClass('is-hidden');
$(contents_sencer + ':nth-child(n + ' + (show + 1) + ')').addClass('is-hidden');
$(contents_nstoplight + ':nth-child(n + ' + (show + 1) + ')').addClass('is-hidden');

const data = $("button[data-link]");

$(data).on('click', function () {
  // スクロールの速度
  const speed = 0; // ミリ秒
  // 移動先を取得
  const targetLink = $(this).attr('data-link');
  const targetId = '#' + targetLink;
  const target = $(targetId);
  // 移動先を数値で取得
  const position = target.offset().top - 100;
  // スムーススクロール
  $('body,html').animate({ scrollTop: position }, speed, 'swing');
  return false;
});

// 引き⼾
$('.more_hikido').on('click', function () {
  // もっと見るボタン
  $(contents_hikido + '.is-hidden').slice(0).removeClass('is-hidden');
  
  if ($(contents_hikido + '.is-hidden').length == 0) {
    $('.more_hikido').fadeOut();

    setTimeout(() => {
      $('.moreClose_hikido').fadeIn();
    }, 500);
  }
});
$('.moreClose_hikido').on('click', function () {
  // 閉じるボタン
  $(contents_hikido + ':nth-child(n + ' + (show + 1) + ')').addClass('is-hidden');

  $('.moreClose_hikido').fadeOut();
  setTimeout(() => {
    $('.more_hikido').fadeIn();
  }, 500);

});

// 折り戸・開き戸
$('.more_orido').on('click', function () {
  // もっと見るボタン
  $(contents_orido + '.is-hidden').slice(0).removeClass('is-hidden');
  
  if ($(contents_orido + '.is-hidden').length == 0) {
    $('.more_orido').fadeOut();

    setTimeout(() => {
      $('.moreClose_orido').fadeIn();
    }, 500);
  }

});
$('.moreClose_orido').on('click', function () {
  // 閉じるボタン
  $(contents_orido + ':nth-child(n + ' + (show5 + 1) + ')').addClass('is-hidden');

  $('.moreClose_orido').fadeOut();
  setTimeout(() => {
    $('.more_orido').fadeIn();
  }, 500);

});

// ワイドオープンドア・ブレークアウトドア
$('.more_wide').on('click', function () {
  // もっと見るボタン
  $(contents_wide + '.is-hidden').slice(0).removeClass('is-hidden');
  
  if ($(contents_wide + '.is-hidden').length == 0) {
    $('.more_wide').fadeOut();

    setTimeout(() => {
      $('.moreClose_wide').fadeIn();
    }, 500);
  }

});
$('.moreClose_wide').on('click', function () {
  // 閉じるボタン
  $(contents_wide + ':nth-child(n + ' + (show5 + 1) + ')').addClass('is-hidden');

  $('.moreClose_wide').fadeOut();
  setTimeout(() => {
    $('.more_wide').fadeIn();
  }, 500);

});

// 防火戸
$('.more_bouka').on('click', function () {
  // もっと見るボタン
  $(contents_bouka + '.is-hidden').slice(0).removeClass('is-hidden');
  
  if ($(contents_bouka + '.is-hidden').length == 0) {
    $('.more_bouka').fadeOut();

    setTimeout(() => {
      $('.moreClose_bouka').fadeIn();
    }, 500);
  }

});
$('.moreClose_bouka').on('click', function () {
  // 閉じるボタン
  $(contents_bouka + ':nth-child(n + ' + (show5 + 1) + ')').addClass('is-hidden');

  $('.moreClose_bouka').fadeOut();
  setTimeout(() => {
    $('.more_bouka').fadeIn();
  }, 500);

});

// センサー・アクセサリ
$('.more_sencer').on('click', function () {
  // もっと見るボタン
  $(contents_sencer + '.is-hidden').slice(0).removeClass('is-hidden');
  
  if ($(contents_sencer + '.is-hidden').length == 0) {
    $('.more_sencer').fadeOut();

    setTimeout(() => {
      $('.moreClose_sencer').fadeIn();
    }, 500);
  }

});
$('.moreClose_sencer').on('click', function () {
  // 閉じるボタン
  $(contents_sencer + ':nth-child(n + ' + (show + 1) + ')').addClass('is-hidden');

  $('.moreClose_sencer').fadeOut();
  setTimeout(() => {
    $('.more_sencer').fadeIn();
  }, 500);

});

// 引き⼾
$('.more_nstoplight').on('click', function () {
  // もっと見るボタン
  $(contents_nstoplight + '.is-hidden').slice(0).removeClass('is-hidden');
  
  if ($(contents_nstoplight + '.is-hidden').length == 0) {
    $('.more_nstoplight').fadeOut();

    setTimeout(() => {
      $('.moreClose_nstoplight').fadeIn();
    }, 500);
  }
});
$('.moreClose_nstoplight').on('click', function () {
  // 閉じるボタン
  $(contents_nstoplight + ':nth-child(n + ' + (show + 1) + ')').addClass('is-hidden');

  $('.moreClose_nstoplight').fadeOut();
  setTimeout(() => {
    $('.more_nstoplight').fadeIn();
  }, 500);

});