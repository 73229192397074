import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const header = $('.header');
const headerElem = document.querySelectorAll('.header');
const bodyScrollLockTarget = document.querySelector('#js-bodyScrollLock');

$(function () {
  
  //メガメニューホバーしたらクラスを付与する
  $('.globalNav_item').hover(function () {
    $(this).toggleClass('active');
    $('.globalNav_child').css('transition', 'opacity 0.3s ease-out');
  });

  // メニューボタン
  $('#js-hamburger').on('click', function () {
    $('body').toggleClass('is-hamburgerOpen');

    if ($(this).attr('aria-expanded') == 'false') {
      $(this).attr('aria-expanded', true);

      $(header).addClass('is-navOpen');

      toggleBodyScrollLock();

    } else {
      $(this).attr('aria-expanded', false);

      const resetNavTitle = $('.globalNav_child');
      $(header).removeClass('is-navOpen');
      
      
      resetNavTitle.each(function() {

        if($(this).hasClass('open')){
          $(this).slideUp();
          $(this).removeClass('open');
        }

      });

      toggleBodyScrollLock();

    }

    const gnavHead = $('.globalNav_link');

    if($(gnavHead).hasClass('is-openHead')){
      $(gnavHead).removeClass('is-openHead');
    }

    $('.globalNav_container').css('transition', 'all 0.4s ease-out');
    setTimeout(() => {
      $('.globalNav_container').css('transition', 'none');
    }, 500);

  });

  $('.navLinkHastag').on('click', function() {

    if($(window).width() > 1200) {

      const globalNavItem = $('.globalNav_item');

      globalNavItem.css('pointer-events', 'none');

      setTimeout(() => {
        globalNavItem.css('pointer-events', 'auto');
      }, 500);
    }

    if($(window).width() < 1199){
      
      $('body').toggleClass('is-hamburgerOpen');
      
      if ($('#js-hamburger').attr('aria-expanded') == 'false') {
        $(this).attr('aria-expanded', true);
  
        $(header).addClass('is-navOpen');
  
        toggleBodyScrollLock();
  
      } else {
        $('#js-hamburger').attr('aria-expanded', false);
  
        const resetNavTitle = $('.globalNav_child');
        $(header).removeClass('is-navOpen');
        
        resetNavTitle.each(function() {
  
          if($(this).hasClass('open')){
            $(this).slideUp();
            $(this).removeClass('open');
          }
  
        });
  
        toggleBodyScrollLock();
      }
    }


  });


  // メニュー内のアコーディオン
  $('.globalNav_link.accordion').on('click', function(e) {

    const navHead = $(this);

    if($(navHead).hasClass('is-openHead')){
      $(navHead).removeClass('is-openHead');

    } else{
      $(navHead).addClass('is-openHead');
      toggleBodyScrollLock();
    }
    
    if($(window).width() < 1199){
      e.preventDefault();
    }

    const navTarget = '#' + $(this).attr('data-navlink');
    $(navTarget).slideToggle();

    if($(navTarget).hasClass('open')){
    } else{
      $(navTarget).addClass('open');
      toggleBodyScrollLock();
    }

      
  });

  function toggleBodyScrollLock() {
    if ($(headerElem).hasClass('is-navOpen')) {
      
      disableBodyScroll(bodyScrollLockTarget, {
        reserveScrollBarGap: true,

      });
    
    } else {
      
      enableBodyScroll(bodyScrollLockTarget, {
        reserveScrollBarGap: true
      });
    }
  }

});