
//topに戻るアイコン
const topBtn = $('.pageTop');
topBtn.hide();
$(window).on('scroll', function () {
  if ($(this).scrollTop() > 400) {
    topBtn.fadeIn();
  } else {
    topBtn.fadeOut();
  }
});