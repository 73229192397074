// メニュー内のアコーディオン
$('.footer_text.accordion').on('click', function(e) {

  const navHead = $(this);

  if($(navHead).hasClass('is-openHead')){
    $(navHead).removeClass('is-openHead');

  } else {
    $(navHead).addClass('is-openHead');
  }

  const navTarget = '#' + $(this).attr('data-link');
  $(navTarget).slideToggle();


  
});
