$(function() {
  //スムーススクロール
  $('a[href^="#"]').on('click', function () {
    // スクロールの速度
    var speed = 800; // ミリ秒
    // アンカーの値取得
    var href = $(this).attr("href");
    // 移動先を取得
    var target = $(href == "#" || href == "" ? 'html' : href);
    // 移動先を数値で取得
    var position = target.offset().top - 0;
    // スムーススクロール
    $('body,html').animate({ scrollTop: position }, speed, 'swing');
    return false;
  });
  
  $('.pageLink_select select').on('change', function () {
    var speed = 800;
    var href = $(this).val();
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top - 60;
    $('body,html').animate({
      scrollTop: position
    }, speed, 'swing');
    return false;
  });
  
  $(window).on('load', function() {
    
    const url = $(location).attr('href');
    if(url.indexOf("#") != -1){
      const anchor = url.split("#");
      const target = $('#' + anchor[anchor.length - 1]);

      if(target.length){
        const pos = Math.floor(target.offset().top) - 90;
        $("html, body").animate({scrollTop:pos}, 500);
      }
    }
  });

});